import type { CookieRef } from '#app'
import {
  defineNuxtRouteMiddleware,
  navigateTo,
  useRuntimeConfig,
  useCookie,
} from '#imports'
import { useUserStore } from '@/store/user'

export default defineNuxtRouteMiddleware((to) => {
  const userStore = useUserStore()
  const config = useRuntimeConfig()

  const userCookie: CookieRef<any> = useCookie('user')
  const vsfStoreCookie = useCookie('magento-store')
  if (!userCookie.value || !vsfStoreCookie.value) {
    userStore.logout()
  }

  const isAuthenticated = userStore.loggedIn
  const isEyeBot = userStore.store?.storeCode === 'eyebot_default_us'
  const isOrderOrTrackingUrl =
    to.path === '/user/order' || to.path === '/tracking'

  // definePageMeta `auth = false`, skip global auth middleware
  if (to.meta.auth === false) return true
  if (
    (isAuthenticated &&
      to.path === '/eyebot' &&
      isEyeBot &&
      userCookie.value?.eyeBotSessionId === to.query.sessionId) ||
    (isAuthenticated &&
      isEyeBot &&
      (to.path.startsWith('/vto') || to.path.startsWith('/prescription')))
  ) {
    return navigateTo('/eyebot-welcome')
  } else if (
    isAuthenticated &&
    (to.path === '/login' ||
      to.path === '/post-login' ||
      (!isEyeBot && to.path.startsWith('/eyebot')))
  ) {
    return navigateTo('/')
  } else if (
    to.path === '/post-login' &&
    !isAuthenticated &&
    !(userStore.credential || (to.query.code && to.query.userEmail))
  ) {
    return navigateTo({
      path: '/login',
      query: { ...to.query, redirect: to.path },
    })
  } else if (
    to.path !== '/login' &&
    to.path !== '/post-login' &&
    to.path !== '/eyebot' &&
    !isAuthenticated
  ) {
    if (
      isOrderOrTrackingUrl &&
      to.query?.id != null &&
      to.query?.id !== '' &&
      to.query?.storeId != null &&
      to.query?.storeId !== '' &&
      to.query?.storeCode != null &&
      to.query?.storeCode !== ''
    ) {
      return true
    } else {
      return navigateTo(
        {
          path: `${config.public.employeeDomain}/login`,
          query: { ...to.query, redirect: to.path },
        },
        {
          external: true,
        },
      )
    }
  }
})
