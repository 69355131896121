import { defineNuxtRouteMiddleware } from 'nuxt/app'
import { useSiteStoreCode } from '@/composables/useSiteStore'
import { useUiState } from '~/composables/useUiState'

export default defineNuxtRouteMiddleware((to) => {
  const {
    changeShowHeaderCartIcon,
    changeShowHeaderUserIcon,
    changeShowHeaderCreditIcon,
    changeShowTopBanner,
  } = useUiState()
  const { isEyeBotSiteStore } = useSiteStoreCode()

  const hideHeaderCartPath = [
    '/vto',
    '/select-lens-type',
    '/prescription',
    '/prescription/manual/edit',
    '/ocr/scan',
    '/ocr/scan/edit',
    '/ocr/upload',
    '/ocr/upload/edit',
    '/checkout/prescriptionedit',
    '/alreadyRedeemed',
    '/eyebotAlreadyRedeemed',
    '/user/order',
    '/tracking',
    '/terms-of-use/employer/v1',
    '/terms-of-use/employer',
    '/terms-of-use/employee',
    '/eyebot-welcome',
  ]

  if (hideHeaderCartPath.includes(to.path)) {
    changeShowHeaderCartIcon(false)
  } else {
    changeShowHeaderCartIcon(true)
  }

  const hideHeaderUserPath = [
    '/user/order',
    '/tracking',
    '/terms-of-use/employee',
    '/terms-of-use/employer/v1',
    '/terms-of-use/employer',
    '/return-policy',
    '/contact-us',
  ]

  if (hideHeaderUserPath.includes(to.path) || isEyeBotSiteStore.value) {
    changeShowHeaderUserIcon(false)
  } else {
    changeShowHeaderUserIcon(true)
  }

  const hideCreditPath = [...hideHeaderCartPath, '/checkout/orderconfirmation']

  if (hideCreditPath.includes(to.path)) {
    changeShowHeaderCreditIcon(false)
  } else {
    changeShowHeaderCreditIcon(true)
  }

  const hideTopBannerPath = [
    '/vto/start',
    '/vto',
    '/select-lens-type',
    '/prescription',
    '/prescription/manual/edit',
    '/ocr/scan',
    '/ocr/scan/edit',
    '/ocr/upload',
    '/ocr/upload/edit',
    '/checkout/prescriptionedit',
    '/alreadyRedeemed',
    '/user/order',
    '/terms-of-use/employer/v1',
    '/terms-of-use/employer',
    '/terms-of-use/employee',
    '/checkout/orderconfirmation',
    '/tracking',
    '/eyebotAlreadyRedeemed',
  ]

  if (
    !isEyeBotSiteStore.value ||
    hideTopBannerPath.includes(to.path) ||
    to.path.endsWith('/select-lens-type')
  ) {
    changeShowTopBanner(false)
  } else {
    changeShowTopBanner(true)
  }
})
