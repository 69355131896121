export const formatCurrency = (
  value: number | string,
  locale: string,
  options: Intl.NumberFormatOptions,
): string => {
  if (value == null) {
    return '--'
  }

  if (typeof value === 'string') {
    value = Number(value)
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    ...options,
  }).format(value)
}
