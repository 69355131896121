import insights from 'search-insights'
import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'

export default defineNuxtPlugin(() => {
  const {
    public: { ALGOLIA_APPLICATION_ID, ALGOLIA_API_KEY },
  } = useRuntimeConfig()
  insights('init', {
    appId: ALGOLIA_APPLICATION_ID as string,
    apiKey: ALGOLIA_API_KEY as string,
  })
})
