import { type UseFetchOptions, useFetch } from 'nuxt/app'
import { omitBy } from 'lodash-es'
import { useNuxtApp } from '#imports'

export const useZenniFetch = async <DataT>(
  path: string,
  options: UseFetchOptions<DataT> = {},
) => {
  if (options.body && !(options.body instanceof FormData)) {
    options.body = JSON.stringify(options.body)
  }

  return await useFetch<DataT>(
    path,
    omitBy(
      {
        $fetch: useNuxtApp().$api,
        ...options,
      },
      (value) => value == null,
    ),
  )
}
