import { useMagentoCookieState } from '../composables/useMagentoCookieState'
import { defineNuxtPlugin } from '#imports'

declare module '#app' {
  interface NuxtApp {
    $magento: {
      config: {
        state: { [key: string]: any }
      }
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const state = useMagentoCookieState()
  nuxtApp.provide('magento', {
    config: {
      state,
    },
  })
})
