import { useCookie, useRuntimeConfig } from '#app'
interface CookieSerializeOptions {
  domain?: string | undefined
  expires?: Date | undefined
  httpOnly?: boolean | undefined
  maxAge?: number | undefined
  path?: string | undefined
  priority?: 'low' | 'medium' | 'high' | undefined
  sameSite?: true | false | 'lax' | 'strict' | 'none' | undefined
  secure?: boolean | undefined
  encode?: (value: string) => string
}

export function useMagentoCookieState() {
  const config = useRuntimeConfig()
  const cookiesNames: { [key: string]: string } = config.public.cookieNames
  const cookiesDefaultOpts: any = {
    httpOnly: false,
    secure: false,
    sameSite: true,
    path: '',
    maxAge: 3600,
  }
  const getCookieName = (property: string): string => cookiesNames[property]
  const cookieOpts = {
    [cookiesNames.currencyCookieName]: {
      ...cookiesDefaultOpts,
    },
    [cookiesNames.localeCookieName]: {
      ...cookiesDefaultOpts,
    },
    [cookiesNames.storeCookieName]: {
      ...cookiesDefaultOpts,
    },
    [cookiesNames.customerCookieName]: {
      ...cookiesDefaultOpts,
    },
    [cookiesNames.cartCookieName]: {
      ...cookiesDefaultOpts,
    },
    [cookiesNames.messageCookieName]: {
      ...cookiesDefaultOpts,
    },
  }

  const getCookieOpt = (cookieName: string): CookieSerializeOptions =>
    cookieOpts[cookieName] ?? ({} as any)

  const cookieNames = {
    cart: getCookieName('cartCookieName'),
    customer: getCookieName('customerCookieName'),
    currency: getCookieName('currencyCookieName'),
    store: getCookieName('storeCookieName'),
    locale: getCookieName('localeCookieName'),
    country: getCookieName('countryCookieName'),
    message: getCookieName('messageCookieName'),
  }

  const createCookieOperations = (cookieName: string) => ({
    get: () => useCookie(cookieName).value,
    set: (value: string, opts?: CookieSerializeOptions) => {
      const cookieOptions = { ...getCookieOpt(cookieName), ...opts }
      const cookieInstance = useCookie(cookieName, cookieOptions)
      cookieInstance.value = value
    },
    remove: () => {
      useCookie(cookieName).value = null
    },
  })

  const {
    get: getCartIds,
    set: setCartIds,
    remove: removeCartIds,
  } = createCookieOperations(cookieNames.cart)

  const {
    get: getCustomerToken,
    set: setCustomerToken,
    remove: removeCustomerToken,
  } = createCookieOperations(cookieNames.customer)

  const {
    get: getStore,
    set: setStore,
    remove: removeStore,
  } = createCookieOperations(cookieNames.store)

  const {
    get: getCurrency,
    set: setCurrency,
    remove: removeCurrency,
  } = createCookieOperations(cookieNames.currency)

  const {
    get: getLocale,
    set: setLocale,
    remove: removeLocale,
  } = createCookieOperations(cookieNames.locale)

  const {
    get: getCountry,
    set: setCountry,
    remove: removeCountry,
  } = createCookieOperations(cookieNames.country)

  const {
    get: getMessage,
    set: setMessage,
    remove: removeMessage,
  } = createCookieOperations(cookieNames.message)
  return {
    getCartIds,
    setCartIds,
    removeCartIds,

    getCustomerToken,
    setCustomerToken,
    removeCustomerToken,

    getStore,
    setStore,
    removeStore,

    getCurrency,
    setCurrency,
    removeCurrency,

    getLocale,
    setLocale,
    removeLocale,

    getCountry,
    setCountry,
    removeCountry,

    getMessage,
    setMessage,
    removeMessage,
  }
}
