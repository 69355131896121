<template>
  <Body class="h-full" />
  <ZnLoadingBar
    v-show="loading"
    wrapper-class="top-[65px] h-[calc(100%-65px)]"
  />
  <NuxtLayout v-show="!loading">
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import { useHead } from '#imports'

const siteConfigurationStore = useSiteConfigurationStore()
const userStore = useUserStore()
const { loading } = storeToRefs(userStore)

useHead({
  title:
    siteConfigurationStore.siteConfiguration?.configuration?.seoConfig?.title,
  meta: [
    {
      name: 'description',
      content:
        siteConfigurationStore.siteConfiguration?.configuration?.seoConfig
          ?.description,
    },
  ],
  script:
    siteConfigurationStore.siteConfiguration?.configuration?.seoConfig?.script,
})
</script>
