import validate from "/usr/app/node_modules/.pnpm/nuxt@3.7.4_eslint@8.51.0_typescript@5.2.2_vue-tsc@1.8.19/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/usr/app/base/middleware/auth.global.ts";
import check_45redeemed_45global from "/usr/app/rdp-employee/middleware/check-redeemed.global.ts";
import ui_45state_45global from "/usr/app/rdp-employee/middleware/ui-state.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global,
  check_45redeemed_45global,
  ui_45state_45global
]
export const namedMiddleware = {}