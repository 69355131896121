import { useFetch, defineNuxtPlugin } from 'nuxt/app'
// TODO change it to RDP
export default defineNuxtPlugin(async (nuxtApp) => {
  const { data: config } = await useFetch<any>('/api/getContent', {
    query: {
      content_type: 'contentKey',
      limit: 1000,
    },
  })
  const contentKey = (config.value?.items as any[])
    ?.map((item) => {
      if (item?.fields) {
        return item.fields
      }
      return null
    })
    .filter(Boolean)
  nuxtApp.provide('contentKey', contentKey)
})
