export enum UserRole {
  AMPortalUser = 'platform:admin:onboard',
  AdminFinance = 'platform:admin:finance',
  PartnerFinance = 'finance',
  Employer = 'employer',
  Employee = 'employee',
  User = 'user',
}

export type UserJWT = {
  applicationId: string
  aud: string
  auth_time: number
  authenticationType: string
  email: string
  email_verified: boolean
  exp: number
  firstName: string
  iat: number
  iss: string
  jti: string
  lastName: string
  roles: UserRole[]
  sid: string
  sub: string
  tid: string
  zenniId: string
}
