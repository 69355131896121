import { isEmpty } from 'lodash-es'
import type { CartGetters as CartGettersBase } from './types'
import type {
  CartServiceProps,
  CartServiceItemProps,
} from '@/types/cartService'
import { formatCurrency } from '@/utils/helper/formatCurrency'
import { EYE_BOT_PRESCRIPTION_ID } from '@/utils/constants/eyeBot'
import { useSiteStoreCode } from '@/composables/useSiteStore'

const getCartItemData = (cartItem: CartServiceItemProps, type: string) => {
  return cartItem?.bundleItems.find((item) => item.type === type)
}

const getItems = (cart: CartServiceProps, itemType: string): any[] => {
  return (
    cart?.items?.map(({ bundleItems }: any) => {
      return bundleItems.find(({ type }: any) => itemType === type)
    }) ?? []
  )
}

const getFrameItems = (cart: CartServiceProps) => {
  return getItems(cart, 'frame').filter((data) => !!data) // eyebot prescription no frame
}

const getEyeBotPrescriptionItemQty = (cart: CartServiceProps) =>
  cart?.items?.filter((item) =>
    item.bundleItems.find(({ sku }: any) => sku === EYE_BOT_PRESCRIPTION_ID),
  )?.length ?? 0

const getFrameItemQty = (cart: CartServiceProps) => {
  return getFrameItems(cart).length
}

const getTotalQty = (cart: CartServiceProps) => {
  const { isEyeBotSiteStore } = useSiteStoreCode()

  if (isEyeBotSiteStore.value) {
    return getFrameItems(cart).length + getEyeBotPrescriptionItemQty(cart)
  }

  return getFrameItems(cart).length
}

const getLensItems = (cart: CartServiceProps) => {
  return getItems(cart, 'lens')
}

const getLensItemQty = (cart: CartServiceProps) => {
  return getLensItems(cart).length
}

const getCartItems = (cart: CartServiceProps) => {
  if (!cart) {
    return []
  } else {
    return cart.items || []
  }
}

const getCartItemPrescription = (cartItem: CartServiceItemProps) => {
  return cartItem?.prescription
}

const getCartItemPrescriptionByCartItemId = (
  cart: CartServiceProps,
  bundleItemId: string,
) => {
  const items = getCartItems(cart)
  const cartItem = items.find((item) => item.bundleItemId === bundleItemId)
  return cartItem && getCartItemPrescription(cartItem)
}

const getSummaryPrices = (
  paymentType: {
    type: string
    balance: number
  },
  cart: CartServiceProps,
  taxAndTotalPrice?: { tax?: number; total?: number },
) => {
  const subtotalIncludingTax = Number(
    cart?.prices?.subtotal_including_tax?.value ?? 0,
  )
  const subtotalExcludingTax = Number(
    cart?.prices?.subtotal_excluding_tax?.value ?? 0,
  )

  const shippingAddressAmount =
    cart?.shipping_addresses?.[0]?.selected_shipping_method?.amount?.value

  const calculatedSalesTax =
    shippingAddressAmount && subtotalIncludingTax - subtotalExcludingTax

  let salesTax
  if (calculatedSalesTax == null) {
    salesTax = 'Calculated at checkout'
  } else if (calculatedSalesTax === 0) {
    salesTax = 'FREE'
  } else {
    salesTax = formatCurrency(
      !isEmpty(taxAndTotalPrice)
        ? taxAndTotalPrice?.tax ?? ''
        : calculatedSalesTax,
      'en-US',
      {
        currency: 'USD',
        minimumFractionDigits: 2,
      },
    )
  }

  const calculatedTotal = !isEmpty(taxAndTotalPrice)
    ? taxAndTotalPrice.total
    : cart?.prices?.grand_total?.value + (shippingAddressAmount ?? 0)

  const creditPrice =
    Number(calculatedTotal) < Number(paymentType.balance)
      ? Number(calculatedTotal)
      : Number(paymentType.balance)

  const discount = Number(cart?.prices?.discounts?.[0]?.amount?.value)

  const total =
    Number(calculatedTotal) < Number(paymentType.balance)
      ? 0
      : Number(calculatedTotal) - Number(paymentType.balance)

  return {
    subtotal: {
      itemNumber: getTotalQty(cart),
      price: subtotalIncludingTax,
    },
    shipping: {
      price: shippingAddressAmount,
    },
    salesTax,
    creditPrice,
    discount,
    total:
      paymentType.balance > 0 && paymentType.type === 'giftCard'
        ? total
        : calculatedTotal,
  }
}

const getVisionPrescriptionByCartItem = (cartItem: CartServiceItemProps) =>
  cartItem?.bundleItems.some(
    (item: { sku: string }) => item.sku === EYE_BOT_PRESCRIPTION_ID,
  )

const getVisionPrescriptionByCart = (cart: CartServiceProps) =>
  cart.items.some(
    (cartItem) =>
      cartItem?.bundleItems.some(
        (item: { sku: string }) => item.sku === EYE_BOT_PRESCRIPTION_ID,
      ),
  )

const hasOnlyRxItem = (cart: CartServiceProps) => {
  if (!cart || !cart.items || cart.items.length !== 1) {
    return false
  }

  const [cartItem] = cart.items
  return getVisionPrescriptionByCartItem(cartItem)
}

const cartGetters: CartGettersBase<CartServiceProps, CartServiceItemProps> = {
  getFrameItems,
  getLensItemQty,
  getFrameItemQty,
  getTotalQty,
  getCartItems,
  getCartItemData,
  getLensItems,
  getCartItemPrescription,
  getCartItemPrescriptionByCartItemId,
  getSummaryPrices,
  getVisionPrescriptionByCartItem,
  getVisionPrescriptionByCart,
  hasOnlyRxItem,
}

export default cartGetters
