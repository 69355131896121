import { useUserStore } from '@/store/user'

export const useUserApi = () => {
  const userStore = useUserStore()
  const subscribe = (status: boolean) => {
    return $fetch<any>(`/platform/api/user/${userStore.user?.zenniId}`, {
      method: 'PATCH',
      headers: {
        'x-authorization-obo': `Bearer ${userStore.userToken}`,
      },
      body: {
        subscribe: status,
      },
    })
  }

  const get = () => {
    return $fetch<any>(`/platform/api/user/${userStore.user?.zenniId}`, {
      method: 'GET',
      headers: {
        'x-authorization-obo': `Bearer ${userStore.userToken}`,
      },
    })
  }
  return { subscribe, get }
}
