import { cloneDeep, isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { Logger } from '@/utils/logger'
import type { PrescriptionTypeProps } from '@/types/prescription'
import type { ShippingCountryProps } from '@/types/address'
import type { FiltersProps } from '@/types/filters'
import type { LensOptionsProps } from '@/types/lensOptions'
import { persistedState, useFetch, useState } from '#imports'

interface ConfigurationProps {
  seoConfig?: {
    title?: string
    description?: string
    script?: any
    [key: string]: any
  }
  algoliaSearchIndex: string
  coatingSku?: string[]
  prismSku?: string
  availableCategories?: any[]
  availableBudgets?: any[]
  redemptionList?: any[]
  lensIndex?: string
  enableChooseCoating?: boolean
  scheduleOpts?: { label: string; val: string; disabled: boolean }[]
  paymentOpts?: { label: string; val: string; disabled: boolean }[]
  weightConfig?: { [key: string]: string }
  billingType?: { label: string; code: string; defaultChosen: boolean }[]
}

interface SiteConfigurationProps {
  configuration: ConfigurationProps
  prescriptionType: PrescriptionTypeProps
  lensOptions: LensOptionsProps
  shippingCountry: ShippingCountryProps
  filters: FiltersProps
  ecpPartnerIds: { label: string; partnerId: string }[]
}

export const useSiteConfigurationStore = defineStore(
  'siteConfiguration',
  () => {
    const siteConfiguration = useState<SiteConfigurationProps>(
      'siteConfiguration',
      () => ({
        configuration: {
          seoConfig: {},
          algoliaSearchIndex: '',
          coatingSku: [],
          prismSku: '',
          availableCategories: [],
          availableBudgets: [],
          redemptionList: [],
          lensIndex: '',
          scheduleOpts: [],
          paymentOpts: [],
          billingType: [],
        },
        prescriptionType: {
          prism: true,
          prescriptionTypeList: [],
        },
        lensOptions: {
          lensTypeListModel: [],
        },
        shippingCountry: {
          countries: [],
          states: {},
        },
        filters: {
          categories: [],
          sizes: [],
          shapes: [],
          colors: [],
        },
        ecpPartnerIds: [],
      }),
    )

    const getSiteConfiguration = async (storeCode: string = 'default') => {
      const { data, error }: any = await useFetch('/api/getContent', {
        query: {
          'fields.name[in]': `${storeCode} site configuration`,
          content_type: 'siteConfiguration',
          limit: 1,
        },
      })

      if (error.value) {
        Logger.error(
          '[Site Configuration Store]: Error fetching site configuration - ',
          error.value,
        )
      } else if (data.value?.items?.[0]?.fields?.configuration) {
        const configuration = JSON.parse(
          data.value.items[0].fields.configuration,
        )

        if (!isEmpty(configuration)) {
          siteConfiguration.value = cloneDeep({
            ...siteConfiguration.value,
            ...data.value.items[0].fields,
            configuration,
          })
        }
      }

      return siteConfiguration.value
    }

    return {
      siteConfiguration,
      getSiteConfiguration,
    }
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
)
