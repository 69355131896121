export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"vmid":"luna-vto","src":"https://bsdk.api.ditto.com/default/5.5.0/en-us/api.js","async":"true"},{"vmid":"luna","src":"https://web.cdn.glasseson.com/glasseson-2.23.1.js","async":"true"},{"vmid":"fittingbox","src":"https://vto-advanced-integration-api.fittingbox.com/index.js","async":"true"},{"src":"https://accounts.google.com/gsi/client"},{"src":"https://cdn.quantummetric.com/network-interceptor/quantum-zennioptical.js","crossorigin":"anonymous"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'