import { useFetch, defineNuxtPlugin } from 'nuxt/app'
export default defineNuxtPlugin((nuxtApp) => {
  const clients = {
    getEntries: async (params: any) =>
      await useFetch('/api/getContent', {
        query: params,
      }),
  }

  nuxtApp.provide('contentfulClient', clients)
})
