import JsSha from 'jssha'
import { defineNuxtPlugin } from 'nuxt/app'
import { useRuntimeConfig } from '#imports'

function getSignature(message: string, secret: string) {
  const unixTime = (new Date().getTime() / 1000) | 0
  const stampedMessage = message + '.' + unixTime
  const shaObj = new JsSha('SHA-512', 'TEXT')
  shaObj.setHMACKey(secret, 'HEX')
  shaObj.update(stampedMessage)
  const hash = shaObj.getHMAC('B64')
  const hashWebsafe = hash
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
  const signature = stampedMessage + '.' + hashWebsafe
  return signature
}

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const message = (runtimeConfig?.public?.vtoPartnerId ?? 'zenni') as string
  const accessKey = (runtimeConfig?.public?.vtoAccessKey ?? '') as string
  const partnerSignature = getSignature(message, accessKey)
  const staticConfig = {
    tryOnServer: runtimeConfig?.public?.vtoServerUrl,
    accessKey: runtimeConfig?.public?.vtoAccessKeyId,
    faceInsightsServer: runtimeConfig?.public?.vtoFaceIqServerUrl,
    partnerSignature,
    partnerId: message,
  }

  const vtoContext = {
    vtoScan: (
      config: { faceInsightsServer: undefined },
      callback: any,
      retake: any,
    ) => {
      if (retake) {
        config.faceInsightsServer = undefined
      }

      return new window.Ditto.Scan({ ...staticConfig, ...config }, callback)
    },
    vtoGetFrontal: (config: any, callback: any, error: any) => {
      return window.Ditto.getFrontalFrame(
        { ...staticConfig, ...config },
        callback,
        error,
      )
    },
    vtoOverlay: (config: any) => {
      return new window.Ditto.Overlay({ ...staticConfig, ...config })
    },
    vtoGetOverlaySignature: (message: any) => {
      return getSignature(message, accessKey)
    },
  }

  nuxtApp.provide('vtoContext', vtoContext)
})
