import { useZenniFetch } from '@/composables/useZenniFetch'
import type { CampaignGiftGetResponseBody } from '@/types/campaign'

export const useGift = () => {
  const getGiftHandle = (data: any[] | null, isEyebot: boolean) => {
    if (
      !data ||
      data.length === 0 ||
      data.filter(
        ({ isActivated, isUsed }: any) =>
          isActivated && (isEyebot ? !isUsed : true),
      ).length === 0
    ) {
      return null
    }
    return data.filter(
      ({ isActivated, isUsed }: any) =>
        isActivated && (isEyebot ? !isUsed : true),
    )[0]
  }
  const getGift = async (opts: any): Promise<CampaignGiftGetResponseBody> => {
    const { data, error } = await useZenniFetch<CampaignGiftGetResponseBody[]>(
      `/redemption/api/campaign/gift`,
      opts,
    )
    const gift = getGiftHandle(data.value, !!opts?.params?.idempotencyKey)
    if (error.value) {
      throw new Error('can not get available policy id')
    }
    return gift
  }

  return {
    getGift,
  }
}
