import { useZenniFetch } from '../useZenniFetch'
import type {
  MagicLinkStartReqInput,
  MagicLinkLoginReqInput,
  MagicLinkLoginResBody,
} from '@/types/magicLink'

export const useMagicLink = () => {
  const start = (params: MagicLinkStartReqInput) => {
    params.loginType = 'magiclink.start'
    return useZenniFetch(`/redemption/api/user/login`, {
      method: 'POST',
      body: params,
    })
  }

  const login = (params: MagicLinkLoginReqInput) => {
    params.loginType = 'magiclink.login'
    return useZenniFetch<MagicLinkLoginResBody>(`/api/login`, {
      method: 'POST',
      body: params,
    })
  }
  return {
    start,
    login,
  }
}
