// Save cookie for all sub domain, example: ".eyewear-dev.zenniservices.com"
export const getCookieDomain = (): string => {
  // @ts-ignore
  const fullUrl = process.browser && (window.location as any)
  let formatDomain = ''

  if (process.env.NODE_ENV === 'development') {
    formatDomain = 'localhost'
  } else if (fullUrl?.host) {
    const url = new URL(fullUrl)
    const urlParts = url.hostname.split('.')
    formatDomain = `.${urlParts.slice(-3).join('.')}`
  }
  return formatDomain
}
