import { navigateTo } from 'nuxt/app'
import { jwtDecode } from 'jwt-decode'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { useUserStore } from '@/store/user'
import type { UserJWT } from '@/types/jwt'

export default defineNuxtPlugin((nuxtApp: any) => {
  const {
    public: { employeeDomain },
  } = useRuntimeConfig()

  const api = $fetch.create({
    async onRequest({ options }) {
      const { userToken, logout } = useUserStore()

      // If token is expired, forward user to login again
      if (userToken) {
        const { exp } = jwtDecode<UserJWT>(userToken)
        const exp2Date = new Date((exp || 0) * 1000)
        const now = new Date(Date.now())
        if (exp2Date.getTime() - now.getTime() < 0) {
          logout()
          await nuxtApp.runWithContext(() =>
            navigateTo(`${employeeDomain}/login`, {
              external: true,
            }),
          )
        }

        if (options.headers) {
          return
        }
        options.headers ||= {}
        options.headers['x-authorization-obo'] = `Bearer ${userToken}`
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        useUserStore().logout()
        await nuxtApp.runWithContext(() =>
          navigateTo(`${employeeDomain}/login`, {
            external: true,
          }),
        )
      }
    },
  })

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api,
    },
  }
})
