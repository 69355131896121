import { computed, reactive } from '#imports'
import type {
  StateInterface,
  UseUiStateInterface,
} from '~/composables/useUiState/useUiState'

const state = reactive<StateInterface>({
  showHeaderCartIcon: true,
  showHeaderUserIcon: true,
  showHeaderCreditIcon: true,
  hideBackButton: false,
  hideTotalItemsBadge: false,
  backButtonFunction: () => null,
  showTopBanner: false,
})

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const showHeaderCartIcon = computed(() => state.showHeaderCartIcon)
  // If you want to use this method within the ui-state middleware.
  const changeShowHeaderCartIcon = (show: boolean) => {
    state.showHeaderCartIcon = show
  }

  const showHeaderUserIcon = computed(() => state.showHeaderUserIcon)
  // If you want to use this method within the ui-state middleware.
  const changeShowHeaderUserIcon = (show: boolean) => {
    state.showHeaderUserIcon = show
  }

  const showHeaderCreditIcon = computed(() => state.showHeaderCreditIcon)
  // If you want to use this method within the ui-state middleware.
  const changeShowHeaderCreditIcon = (show: boolean) => {
    state.showHeaderCreditIcon = show
  }

  const showTopBanner = computed(() => state.showTopBanner)
  const changeShowTopBanner = (show: boolean) => {
    state.showTopBanner = show
  }

  const scrollToTop = () => {
    if (process.client) {
      window.scrollTo(0, 0)
    }
  }

  const hideBackButton = computed(() => state.hideBackButton)
  const setHideBackButton = (hideBackButton: boolean) => {
    state.hideBackButton = hideBackButton
  }
  const setBackButtonFunction = (backButtonFunction: () => void) => {
    state.backButtonFunction = backButtonFunction
  }

  const executeBackButtonFunction = () => {
    if (state.backButtonFunction) {
      state.backButtonFunction()
    }
  }

  const hideTotalItemsBadge = computed(() => state.hideTotalItemsBadge)
  const changeHideTotalItemsBadge = (hideTotalItemsBadge: boolean) => {
    state.hideTotalItemsBadge = hideTotalItemsBadge
  }

  return {
    showHeaderCreditIcon,
    changeShowHeaderCreditIcon,
    showHeaderCartIcon,
    changeShowHeaderCartIcon,
    showHeaderUserIcon,
    changeShowHeaderUserIcon,
    hideBackButton,
    hideTotalItemsBadge,
    changeHideTotalItemsBadge,
    executeBackButtonFunction,
    scrollToTop,
    setBackButtonFunction,
    setHideBackButton,
    showTopBanner,
    changeShowTopBanner,
  }
}

export default useUiState
export * from './useUiState'
