import { default as _91_46_46_46slug_93vEbwI6fcI9Meta } from "/usr/app/rdp-employee/pages/[...slug].vue?macro=true";
import { default as alreadyRedeemedIQKm5GEdh0Meta } from "/usr/app/rdp-employee/pages/alreadyRedeemed.vue?macro=true";
import { default as eyebotNB5lph73L5Meta } from "/usr/app/rdp-employee/pages/eyebot.vue?macro=true";
import { default as index91S7VYKen5Meta } from "/usr/app/rdp-employee/pages/index.vue?macro=true";
import { default as indexSoFtMel3GSMeta } from "/usr/app/rdp-employee/pages/login/index.vue?macro=true";
import { default as post_45loginGVUvkIZzL4Meta } from "/usr/app/rdp-employee/pages/post-login.vue?macro=true";
import { default as employeeHpRup6pJqFMeta } from "/usr/app/rdp-employee/pages/terms-of-use/employee.vue?macro=true";
import { default as indexX7Bsnp6I8SMeta } from "/usr/app/rdp-employee/pages/terms-of-use/employer/index.vue?macro=true";
import { default as v1bjewBbIoydMeta } from "/usr/app/rdp-employee/pages/terms-of-use/employer/v1.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93vEbwI6fcI9Meta?.name ?? "slug",
    path: _91_46_46_46slug_93vEbwI6fcI9Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93vEbwI6fcI9Meta || {},
    alias: _91_46_46_46slug_93vEbwI6fcI9Meta?.alias || [],
    redirect: _91_46_46_46slug_93vEbwI6fcI9Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: alreadyRedeemedIQKm5GEdh0Meta?.name ?? "alreadyRedeemed",
    path: alreadyRedeemedIQKm5GEdh0Meta?.path ?? "/alreadyRedeemed",
    meta: alreadyRedeemedIQKm5GEdh0Meta || {},
    alias: alreadyRedeemedIQKm5GEdh0Meta?.alias || [],
    redirect: alreadyRedeemedIQKm5GEdh0Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/alreadyRedeemed.vue").then(m => m.default || m)
  },
  {
    name: eyebotNB5lph73L5Meta?.name ?? "eyebot",
    path: eyebotNB5lph73L5Meta?.path ?? "/eyebot",
    meta: eyebotNB5lph73L5Meta || {},
    alias: eyebotNB5lph73L5Meta?.alias || [],
    redirect: eyebotNB5lph73L5Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/eyebot.vue").then(m => m.default || m)
  },
  {
    name: index91S7VYKen5Meta?.name ?? "index",
    path: index91S7VYKen5Meta?.path ?? "/",
    meta: index91S7VYKen5Meta || {},
    alias: index91S7VYKen5Meta?.alias || [],
    redirect: index91S7VYKen5Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSoFtMel3GSMeta?.name ?? "login",
    path: indexSoFtMel3GSMeta?.path ?? "/login",
    meta: indexSoFtMel3GSMeta || {},
    alias: indexSoFtMel3GSMeta?.alias || [],
    redirect: indexSoFtMel3GSMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: post_45loginGVUvkIZzL4Meta?.name ?? "post-login",
    path: post_45loginGVUvkIZzL4Meta?.path ?? "/post-login",
    meta: post_45loginGVUvkIZzL4Meta || {},
    alias: post_45loginGVUvkIZzL4Meta?.alias || [],
    redirect: post_45loginGVUvkIZzL4Meta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/post-login.vue").then(m => m.default || m)
  },
  {
    name: employeeHpRup6pJqFMeta?.name ?? "terms-of-use-employee",
    path: employeeHpRup6pJqFMeta?.path ?? "/terms-of-use/employee",
    meta: employeeHpRup6pJqFMeta || {},
    alias: employeeHpRup6pJqFMeta?.alias || [],
    redirect: employeeHpRup6pJqFMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/terms-of-use/employee.vue").then(m => m.default || m)
  },
  {
    name: indexX7Bsnp6I8SMeta?.name ?? "terms-of-use-employer",
    path: indexX7Bsnp6I8SMeta?.path ?? "/terms-of-use/employer",
    meta: indexX7Bsnp6I8SMeta || {},
    alias: indexX7Bsnp6I8SMeta?.alias || [],
    redirect: indexX7Bsnp6I8SMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/terms-of-use/employer/index.vue").then(m => m.default || m)
  },
  {
    name: v1bjewBbIoydMeta?.name ?? "terms-of-use-employer-v1",
    path: v1bjewBbIoydMeta?.path ?? "/terms-of-use/employer/v1",
    meta: v1bjewBbIoydMeta || {},
    alias: v1bjewBbIoydMeta?.alias || [],
    redirect: v1bjewBbIoydMeta?.redirect || undefined,
    component: () => import("/usr/app/rdp-employee/pages/terms-of-use/employer/v1.vue").then(m => m.default || m)
  }
]